import { CircularProgress, Grid, Box } from "@material-ui/core";
import React from "react";
import { connectDapp } from "./eth/contract";
import DappInfo from "./components/DappInfo";
import abiPXR from "./eth/contract.abiPXR";
import abiCWM from "./eth/contract.abiCWM";
import abiCW from "./eth/contract.abiCW";
// import Card from "./components/Card";

//assets
import Metamask from "./assets/metamask-fox.svg";
import Banner from "./assets/banner.png";
import Font from "./assets/font.png";
import FooterImage from "./assets/footerImage.jpg";

let contract = {};
let web3;

const App = () => {
  const [data, setData] = React.useState([]);
  const [showInfo, setShowInfo] = React.useState(false);
  const [hasWeb3, setHasWeb3] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [fullyLoaded, setFullyLoaded] = React.useState(false);

  const makeConnection = async () => {
    const [_web3, cPXR, cCWM, cCW] = await connectDapp(abiPXR, abiCWM, abiCW);
    setShowInfo(true);
    web3 = _web3;
    setHasWeb3(true);
    contract.pxr = cPXR;
    contract.cwm = cCWM;
    contract.cw = cCW;
    fetchData();
  };

  const fetchData = async () => {
    if (!web3 || !contract || !window.ethereum) {
      setLoading(false);
      return;
    }
    let id = 2;
    let account = window.ethereum.selectedAddress;
    console.log(account);

    let resultPXR = await fetchPXR(account);

    let resultCWM = await fetchCWM(account, resultPXR);

    let resultCW = await fetchCW(account, resultPXR, resultCWM);
    let result = [].concat(resultPXR, resultCWM, resultCW);
    console.log(result);
    setData(result);
    setLoading(false);
    setFullyLoaded(true);
  };

  const fetchPXR = async (account) => {
    let result = [];
    let id = 6;
    while (true) {
      let uri = await contract.pxr.methods.uri(id).call();
      if (!uri.includes("//") && id >= 70) {
        console.log("ID:");
        console.log(id);
        return result;
      }
      let balance = await contract.pxr.methods.balanceOf(account, id).call();
      balance = parseInt(balance);
      if (balance > 0) {
        let base = "https://ipfs.io/";
        let hash = uri.split("ipfs://")[1];
        let meta = await fetch(base + hash).then((res) => res.json());
        let imageHash = meta.image.split("ipfs://")[1];
        let image = await fetch(base + imageHash).then((res) => res.blob());
        let urlCreator = window.URL || window.webkitURL;
        image = urlCreator.createObjectURL(image);
        let name = meta.name;
        result.push({ id, balance, name, image, type: "PXR" });
        setTimeout(() => {
          setData(result);
          setLoading(false);
        });
      }

      id += 1;
    }
  };

  const fetchCWM = async (account, resultPXR) => {
    let result = [];
    let balanceOwner = await contract.cwm.methods.balanceOf(account).call();
    for (let index = 0; index < balanceOwner; index++) {
      let id = await contract.cwm.methods
        .tokenOfOwnerByIndex(account, index)
        .call();
      let uri = await contract.cwm.methods.tokenURI(id).call();
      let meta = await fetch(uri).then((res) => res.json());
      let image = await fetch(meta.image).then((res) => res.blob());
      let urlCreator = window.URL || window.webkitURL;
      image = urlCreator.createObjectURL(image);
      let name = meta.name;
      result.push({ id, name, image, type: "CWM" });
      setTimeout(() => {
        let temp_result = [].concat(resultPXR, result);
        setData(temp_result);
        setLoading(false);
      });
    }
    return result;
  };

  const fetchCW = async (account, resultPXR, resultCWM) => {
    let result = [];
    let balanceOwner = await contract.cw.methods.balanceOf(account).call();
    for (let index = 0; index < balanceOwner; index++) {
      let id = await contract.cw.methods
        .tokenOfOwnerByIndex(account, index)
        .call();
      let uri = await contract.cw.methods.tokenURI(id).call();
      let meta = await fetch(uri).then((res) => res.json());
      let image = await fetch(meta.image).then((res) => res.blob());
      let urlCreator = window.URL || window.webkitURL;
      image = urlCreator.createObjectURL(image);
      let name = meta.name;
      result.push({ id, name, image, type: "CW" });
      setTimeout(() => {
        let temp_result = [].concat(resultPXR, resultCWM, result);
        setData(temp_result);
        setLoading(false);
      });
    }
    return result;
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          // justifyContent: "center",
          flexDirection: "column",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        {/* Footer */}
        <div style={{ position: "absolute", bottom: 20, left: 10 }}>
          <img src={FooterImage} width={500} />
        </div>
        <div
          style={{
            marginTop: 40,
            marginBottom: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img draggable={false} src={Font} width={220} />
        </div>

        <img
          draggable={false}
          style={{ borderRadius: 5 }}
          src={Banner}
          width={1000}
        />

        <div
          style={{
            marginTop: 60,
            marginBottom: 100,
            width: "90%",
            maxWidth: 1000,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!hasWeb3 ? (
            <div
              onClick={() => makeConnection()}
              style={{
                width: 180,
                height: 50,
                borderRadius: 25,
                background: "#6b6262",
                color: "white",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <div style={{ marginRight: 10 }}> Connect</div>
              <img src={Metamask} width={40} />
            </div>
          ) : loading ? (
            <CircularProgress style={{ color: "#7D1F25" }} />
          ) : data.length > 0 ? (
            <Grid container spacing={5} style={{ marginBottom: 20 }}>
              {data.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} md={4}>
                    {/* <Card pair={item} /> */}
                    <Box
                      boxShadow={3}
                      style={{
                        position: "relative",
                        borderRadius: 5,
                        width: "100%",
                        height: 420,
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        style={{ marginTop: 40 }}
                        src={item.image}
                        height={230}
                      />
                      {/* Quantity */}
                      {item.type == "PXR" && (
                        <div
                          style={{
                            color: "white",
                            fontWeight: 500,
                            position: "absolute",
                            right: "5%",
                            top: "4%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#7D1F25",
                            borderRadius: "50%",
                            height: 40,
                            width: 40,
                          }}
                        >
                          x{item.balance}
                        </div>
                      )}
                      <div
                        style={{
                          opacity: 0.2,
                          height: 2,
                          width: "90%",
                          marginTop: 30,
                          marginBottom: 30,
                          backgroundColor: "#82817d",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "80%",
                          textAlign: "center",
                          fontWeight: 500,
                          color: "#6d6c6a",
                        }}
                      >
                        {item.name}
                      </div>
                    </Box>
                  </Grid>
                );
              })}
              {!fullyLoaded && (
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      width: "100%",
                      height: 420,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress style={{ color: "#7D1F25" }} />
                  </div>
                </Grid>
              )}
            </Grid>
          ) : (
            <div>No Items</div>
          )}
        </div>
        {/* ShowInfo */}
        {showInfo && <DappInfo />}
      </div>
    </div>
  );
};

export default App;
