import Web3 from "web3";
import isDapp from "./dapp";

export const connectDapp = async (abiPXR, abiCWM, abiCW) => {
  let web3;

  if (await isDapp()) {
    web3 = new Web3(window.web3.currentProvider);
    if (!(web3.currentProvider.networkVersion == 1)) {
      web3 = new Web3(
        new Web3.providers.HttpProvider(
          "https://mainnet.infura.io/v3/4c63edab283a4ba1b145a17f095be4e3"
        )
      );
    }
  } else {
    web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://mainnet.infura.io/v3/4c63edab283a4ba1b145a17f095be4e3"
      )
    );
  }
  const contractAddressPXR = "0xb610F0E48FBec040Fc803Fb3A4FB66fA39c525c9";
  const contractAddressCWM = "0xCB5ee36C34464250B9ea24aE7C2301EA90B5829D";
  const contractAddressCW = "0xE73c9D94A542b1708450E13D12d4B38204dFD72c";
  const contractPXR = new web3.eth.Contract(abiPXR, contractAddressPXR);
  const contractCWM = new web3.eth.Contract(abiCWM, contractAddressCWM);
  const contractCW = new web3.eth.Contract(abiCW, contractAddressCW);
  return [web3, contractPXR, contractCWM, contractCW];
};

export const checkNetwork = async () => {
  if (!(await isDapp())) return true;
  let netId = window.ethereum.networkVersion;
  if (netId == 1) {
    return true;
  }
  return false;
};
